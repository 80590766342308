.loader {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 300px; /* Set height to 100% */
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10000;
}

.overlay-spinner {
	width: 36px;
	height: 32px;
	--c: linear-gradient(rgba(87, 118, 231, 1) 0 0);
	background: var(--c) 0% 50%, var(--c) 50% 50%, var(--c) 100% 50%;
	background-size: 7.2px 100%;
	background-repeat: no-repeat;
	animation: bars-t0lx83sm 1s infinite linear;
}

.bars {
	width: 36px;
	height: 32px;
	--c: linear-gradient(rgba(87, 118, 231, 1) 0 0);
	background: var(--c) 0% 50%, var(--c) 50% 50%, var(--c) 100% 50%;
	background-size: 7.2px 100%;
	background-repeat: no-repeat;
	animation: bars-t0lx83sm 1s infinite linear;
}

@keyframes bars-t0lx83sm {
	33% {
		background-size: 7.2px 10%, 7.2px 100%, 7.2px 100%;
	}

	50% {
		background-size: 7.2px 100%, 7.2px 10%, 7.2px 100%;
	}

	66% {
		background-size: 7.2px 100%, 7.2px 100%, 7.2px 10%;
	}
}
