.service-categories {
	position: relative;
}
.click-all-that {
	position: relative;
	color: #b5b5b5;
}
.sec-title {
	/* width: 100%; */
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	box-sizing: border-box;
	gap: 6px;
	text-align: left;
	font-size: var(--font-size-xs);
	color: #ffb082;
	font-family: var(--default-font);
	flex: 1;
}

.sec-title-wrapper {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}
