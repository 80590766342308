@import "./globalStyles.css";

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
::-webkit-scrollbar {
	width:.5rem; /* width of the scrollbar */
  }
  ::-webkit-scrollbar-track {
	background: #f1f1f1; /* color of the track */
  }
  ::-webkit-scrollbar-thumb {
	background: #c5c3c3; /* color of the thumb */
	border-radius: .5rem; /* roundness of the thumb */
  }