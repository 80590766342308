/* customQuillStyles.css */
.custom-quill-editor {
	/* height: 100px; */
	border-radius: 10px;
	/* background-color: aliceblue;
	border: none; */
}
.quill .custom-quill-editor {
	border-radius: 10px;
}

.ql-toolbar.ql-snow {
	border: 1px solid #ccc;
	box-sizing: border-box;
	border-radius: 10px 10px 0px 0px;
	font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
	padding: 8px;
	color: #ccc;
}

.ql-container.ql-snow {
	border: 1px solid #ccc;
	border-radius: 0px 0px 10px 10px;
	min-height: 100px;
}

.custom-quill-editor .ql-editor {
	color: white;
	/* border-color: #243161; */
}
.custom-quill-editor .ql-toolbar.ql-snow .ql-picker-label {
	color: #fcfcfc;
}

.custom-quill-editor .ql-snow .ql-stroke {
	stroke: #ffffff;
}

.custom-quill-editor .ql-fill {
	fill: #243161;
}

.custom-quill-editor .ql-container.ql-snow {
	border-color: #243161;
}

.custom-quill-editor .ql-editor a {
	color: #243161;
}

.custom-quill-editor .ql-toolbar {
	border-color: #243161;
}

.custom-quill-editor .ql-toolbar button {
	color: #243161;
}

.custom-quill-editor .ql-toolbar button:hover {
	color: #243161;
}
